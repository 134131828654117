<template>
  <div class="course-ppt">
    <slot v-if="list && list.length>0">
      <img :src="list[0].url" alt="">
      <slot v-if="pagination>0">
        <slot v-for="i in pagination*10">
          <!-- <img v-image-preview v-if="list[i]" :src="list[i].url" alt=""> -->
          <img v-if="list[i]" v-lazy="list[i].url" alt="" @click="toPreview(i)">
        </slot>
      </slot>
      <p>
        <span v-if="pagination*10 < list.length" @click="openMore">加载后十页图片</span>
        <span v-else @click="closeAll">收起全部图片</span>
      </p>
    </slot>
    <p v-else class="ppt_tip">该PPT不能公开展示</p>
    <!-- toPreview -->
    <div class="ppt_preview" v-if="isPreview" @click="closePreview">
      <img v-if="preview>=0" :src="list[preview].url" alt="">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: [Array],
    page: [String, Number],
  },
  data() {
    return {
      pagination: null,
      isPreview: false,
      preview: -1,
    }
  },
  watch: {
    page: {
      handler(_new, _old) {
        if(!_old && (_new || _new===0 || _new==='0')) {
          this.pagination = _new;
        }
      },
      immediate: true,
    },
    pagination: {
      handler(_new, _old) {
        if(_new===0 || _new==='0') {
          // 初始化
        } else if(_new) {
          // 存在点击事件
        }
      },
      immediate: true,
    }
  },
  methods: {
    openMore() {
      this.pagination ++;
    },
    closeAll() {
      this.pagination = 0;
    },
    toPreview(index) {
      this.isPreview = true;
      this.preview = index;
    },
    closePreview() {
      this.isPreview = false;
      this.preview = -1;
    },
  },
}
</script>

<style lang="scss">
.vue-directive-image-previewer {
  z-index: 500;
}
.vue-directive-image-previewer-body {
  overflow: auto;
  img {
    height: auto!important;
  }
}

.course-ppt {
  img {
    width: 100%;
    max-width: 700px;
    max-height: 395px;
    margin-bottom: 22px;
    // border: 1px solid rgba(225, 225, 225, 0.3);
    // box-shadow: 0px 0px 5px rgba(200,200,200,0.3);
    // box-shadow: 0px 0px 5px rgba(0, 49, 119, 0.25); // #003177;
    box-shadow: 0px 1px 5px rgba(25, 150, 190, 0.2); // #003177;
    // object-fit: none;
    // object-position: center;
    // background-color: rgba(185,233,251,0.3);
    // background-color: rgba(25, 150, 190, 0.15);
    background-color: #ffffff;
    background-image: url('../assets/img/logo2022.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 200px auto;
  }
  p {
    width: 100%;
    max-width: 700px;
    margin-bottom: 0;
    span {
      display: block;
      width: max-content;
      line-height: 40px;
      font-size: 18px;
      padding: 0 30px;
      margin: 0 auto;
      color: #ffffff;
      background-color: #003177;
      border-radius: 20px;
      cursor: pointer;
    }
    &.ppt_tip {
      font-size: 16px;
      font-family: PingFang SC Regular;
      font-weight: 400;
      color: #999999;
      line-height: 30px;
    }
  }
  .ppt_preview {
    width: 100vw;
    height: 100vh;
    padding: 15px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
    background-color: rgba(0, 0, 0, 0.5);
    display: grid;
    justify-content: center;
    align-items: center;
    overflow: auto;
    img {
      width: 60vw;
      min-width: 700px;
      max-width: 1200px;
      height: auto;
      max-height: 100vh;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  @media (max-width:767px) {
    img {
      background-size: 100px auto;
    }
    p span {
      font-size: 15px;
    }
    .ppt_preview {
      img {
        width: 90vw;
        min-width: 0;
      }
    }
  }
}
</style>